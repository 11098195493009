import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';

import server from '../constants/server';
import log from '../components/Logger';
import {hexDecode} from '../components/common/hex';
import appVersionSpecific from '../appVersionSpecific.ortho';
import MyStore from "../actions/MyStore";

PouchDB.plugin(PouchDBFind);

const MyPrefixedPouch = PouchDB.defaults({

});

const syncOpts = {
  live: true,
  retry: true
};

const localDatabases = (() => {
  let instance;

  function createInstance(user) {

    return {
      v1databaseRepository: new MyPrefixedPouch(`userdb-${user}`)
    };
  }

  return {
    getInstance: () => {
      const user = MyStore.get(appVersionSpecific.electronStoreUserKey);

      if (!instance && user) {
        instance = createInstance(user);
        instance.v1databaseRepository.createIndex({
          index: { fields: ['lastName', 'dbtype'], name: 'patient.lastName' }
        });
        instance.v1databaseRepository.createIndex({
          index: { fields: ['archived', 'dbtype', 'firstName'], name: 'patient.firstName' }
        });
        instance.v1databaseRepository.createIndex({
          index: { fields: ['archived', 'rdvDay', 'dbtype'], name: 'patient.rdvDay' }
        });

        instance.v1databaseRepository.createIndex({
          index: { fields: ['none', 'lookup', 'dbtype'], name: 'patient.lookup' }
        });

        instance.v1databaseRepository.createIndex({
          index: { fields: ['patientId', 'dbtype'], name: 'anamnesis.patientID' }
        });

        instance.v1databaseRepository.createIndex({
          index: { fields: ['patientId', 'dbtype'], name: 'bilan.patientID' }
        });

        instance.v1databaseRepository.createIndex({
          index: { fields: ['name', 'dbtype'], name: 'anamnesisModel.name' }
        });

        instance.v1databaseRepository.createIndex({
          index: { fields: ['label', 'dbtype'], name: 'checkupModel.label' }
        });

        instance.v1databaseRepository.createIndex({
          index: { fields: ['k', 'dbtype'], name: 'user.key' }
        });

        instance.v1databaseRepository.createIndex({
          index: { fields: ['testName', 'dbtype'], name: 'testConfig.testName' }
        });

        instance.v1databaseRepository.createIndex({
          index: { fields: ['bilanId', 'dbtype'], name: 'cotation.bilanId' }
        });

        instance.v1databaseRepository.createIndex({
          index: { fields: ['bilanId', 'dbtype'], name: 'comments.bilanId' }
        });
        const aesKey = MyStore.get(appVersionSpecific.electronStoreAesKey);
        if (user && aesKey && appVersionSpecific.remoteDbConnection) {
          synchroniseDbs();
        }
      }
      return instance;
    },
    getNewEmailInstance: (newUser) => {
      const newUserInstance = createInstance(newUser);
      const user = MyStore.get(appVersionSpecific.electronStoreUserKey);
      const instance = createInstance(user);
      handleSyncEvents(instance.v1databaseRepository.sync(newUserInstance.v1databaseRepository, syncOpts));
    }
  };
})();

const remoteDatabases = (() => {
  let instance;
  function createInstance() {
    const user = MyStore.get(appVersionSpecific.electronStoreUserKey);
    const aesKey = MyStore.get(appVersionSpecific.electronStoreAesKey);

    const RemotePouch = PouchDB.defaults({
      prefix: server.secured.couchDbUrl,
      auth: {
        username: hexDecode(user),
        password: aesKey.replaceAll('§', '')
      }
    });

    return {
      v1databaseRepositoryRemote: new RemotePouch(`userdb-${user}`)
    };
  }
  return {
    getInstance: () => {
      const user = MyStore.get(appVersionSpecific.electronStoreUserKey);
      const aesKey = MyStore.get(appVersionSpecific.electronStoreAesKey);
      if (!instance && user && aesKey) {
        instance = createInstance();
      }
      return instance;
    }
  };
})();

function handleSyncEvents(tableSync) {
  tableSync
    .on('change', change => {
      log.info('DB sync change status', change);
    })
    .on('paused', info => {
      // replication was paused, usually because of a lost connection
      log.info('DB sync paused status', info);
    })
    .on('active', info => {
      // replication was resumed
      log.info('DB sync active status', info);
    })
    .on('error', err => {
      // totally unhandled error (shouldn't happen)
      log.info('DB sync error status', err);
    });
}

const synchroniseDbs = () => {
  handleSyncEvents(
    localDatabases
      .getInstance()
      .v1databaseRepository.sync(remoteDatabases.getInstance().v1databaseRepositoryRemote, syncOpts)
  );
};

export default localDatabases;
