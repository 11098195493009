// returns an object with following keys :
// count : number of row in passation that can be imported
// values : an object with entry key the test name and value the properties of the test result
// calculOption : the calculation table to be used after import
// calculOptionType: string, 'age' or 'classe' depending on calculation table key
import ETAL from '../../../../../constants/tests/ETAL.json';

import {happyneuron2021FileFormat} from "../HappyNeuronImporterUtil";

const executeImport = ({ data, classe }) => {
    const calculValue = ETAL.calcul.find(({ id }) => id === classe);

    const rawResultTransformationFunction = ({eprId, score, annotation}) => {
        if (!score && !annotation && score !== 0) return null;
        if (!calculValue && !calculValue.data) return {eprId, score, annotation};
        const {showWarningLowPopulation} = calculValue.data[eprId] || {};
        if (showWarningLowPopulation) {
            const warning =
                "(*) En raison d'une cohorte minime sur cette tranche d'âge/NSC, les résultats sont à interpréter avec précaution";
            return {eprId, score, annotation: `${annotation ? `${annotation} <br/>` : ''}${warning}`};
        }
        return {eprId, score, annotation};
    };

    return happyneuron2021FileFormat.executeImport({data, classe, test: ETAL, rawResultTransformationFunction, positionCorrectionFunction: index => index - 1,});
};

export default executeImport;
